import React from 'react'
// import { Card } from 'react-bootstrap'
import { Link } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import DateBox from '../../components/misc/date-box'
import ArrowLink from '../../components/misc/arrow-link'
// import AddToCalendar from 'react-add-to-calendar'
import moment from 'moment-timezone'

import loadable from '@loadable/component'
const OYAddToCalendar = loadable(() =>
  import('../../components/misc/add-to-calendar')
)
import GPTUnit from '../../components/misc/gpt-unit'
// import OYAddToCalendar from '../../components/misc/add-to-calendar'

export default ({ event }) => {
  const startDate = moment.tz(event.startDate, 'US/Eastern')
  const endDate = moment.tz(event.endDate, 'US/Eastern')
  const isLive =
    event.video && moment.tz('US/Eastern').isBetween(startDate, endDate)

  const isPlayable =
    isLive || (endDate.isBefore() && event.video && !event.video.isLiveStream)

  return (
    <div
      className="event-card"
      data-sal="slide-left"
      data-sal-delay={0}
      data-sal-duration={300}
      data-sal-easing="ease-out"
    >
      <div className="event-card-hero">
        <DateBox
          dateString={event.startDate}
          showTime={false}
          backgroundColor={event.colorPalette.primaryColor}
          primaryColor="FFF"
          secondaryColor="FFF"
        />
        <div className="pl-3 pl-md-5">
          <span className="event-display-time">
            {startDate.format('h:mm A z')}
          </span>
          <Link to={`/events/${event.slug}`} className="text-decoration-none">
            <h3 className="hu h2 event-name">
              {event.name}{' '}
              {event.gptAdUnit ? (
                <GPTUnit {...event.gptAdUnit} invert />
              ) : null}
            </h3>
          </Link>
          <div
            dangerouslySetInnerHTML={{
              __html: event.teaser.childMarkdownRemark.html,
            }}
          />

          {startDate.isAfter() ? (
            <OYAddToCalendar
              event={{
                title: event.calendarInviteTitle || event.name,
                description: event.teaser.teaser,
                location: `https://oneyard.co/events/${event.slug}`,
                startTime: event.startDate,
                endTime: event.endDate,
              }}
              color="000"
              arrowColor={event.colorPalette.primaryColor}
              buttonLabel="Add to Calendar"
              className="arrow-link"
            />
          ) : (
            <ArrowLink color="f9d021" to={`/events/${event.slug}`}>
              {isLive
                ? 'Watch Live'
                : isPlayable
                ? 'Watch Video'
                : 'Stay tuned for the replay!'}
            </ArrowLink>
          )}
        </div>
      </div>
    </div>
  )
}
