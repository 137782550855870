import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import EventCard from '../components/events/card'
import CategoryNav from '../components/categories/nav'
import { Container, Row, Col } from 'react-bootstrap'
import LayoutContext from '../components/layout/context'

class EventsIndex extends React.Component {
  static contextType = LayoutContext

  constructor(props) {
    super()

    this.state = {
      events: get(props, 'data.allContentfulEvent.edges'),
    }
  }

  componentWillMount() {
    this.context.set({
      meta: get(this, 'props.data.contentfulMetaTags'),
    })
  }

  render() {
    const { events } = this.state

    return (
      <>
        <div
          style={{
            borderBottom: '2px solid #000',
          }}
        >
          <Container className="py-5" fluid="lg">
            <Row>
              <Col md="3" className="pb-3 pb-md-0">
                <h2 className="h2 hu">Event Schedule</h2>
              </Col>
              <Col>
                {events.map(({ node }) => {
                  return (
                    <div key={node.slug} className=" list-item-w-divider">
                      <EventCard event={node} />
                    </div>
                  )
                })}
              </Col>
            </Row>
          </Container>
        </div>
        <CategoryNav />
      </>
    )
  }
}

export default EventsIndex

export const pageQuery = graphql`
  query EventIndexQuery {
    contentfulMetaTags(pagePath: { eq: "/events" }) {
      title
      image {
        gatsbyImageData(width: 1200, height: 630)
      }
      url
      description {
        description
      }
    }
    allContentfulEvent(
      filter: { contentful_id: { ne: "3FVs0RszkWF9ioi31DH8IU" } }
      sort: { fields: [startDate], order: ASC }
    ) {
      edges {
        node {
          name
          slug
          startDate
          endDate
          calendarInviteTitle
          teaser {
            childMarkdownRemark {
              html
            }
            teaser
          }
          video {
            isLiveStream
          }
          gptAdUnit {
            slot
            width
            height
            fullColor
            lede
          }
          colorPalette {
            primaryColor
            secondaryColor
            backgroundColorLight
          }
        }
      }
    }
  }
`
